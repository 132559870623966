import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export const TransactionsColumn = [
  {
    name: "Payment mode",
  },
  {
    name: "User Name",
    selector: (row) => row.name,
  },
  {
    name: "Amount",
    selector: (row) => row.email,
  },
  {
    name: "Date",
    selector: (row) => row.phone,
  },
  {
    name: "Payment Method",
    selector: (row) => row.phone,
  },
  {
    name: "Payment Status",
    selector: (row) => row.phone,
  },
  {
    name: "Actions",
    cell: (row) => (
      <div className="tbl_btns">
        <button className="btns" userId={`${row.id}`}>
          <FontAwesomeIcon icon={faEye} />
        </button>
      </div>
    ),
    width: "150px",
  },
];
