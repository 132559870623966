import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export const TripColumn = [
  {
    name: "Trip Id",
    cell: (row, index) => index + 1,
  },
  {
    name: "Driver Name",
    selector: (row) => row.name,
  },
  {
    name: "Status",
    selector: (row) => row.email,
  },
  {
    name: "Created",
    selector: (row) => row.phone,
  },
  {
    name: "Actions",
    cell: (row) => (
      <div className="tbl_btns">
        <button className="btns" userId={`${row.id}`}>
          <FontAwesomeIcon icon={faEye} />
        </button>
      </div>
    ),
    width: "150px",
  },
];
