import React from "react";
import MasterLayout from "../layout/MasterLayout";
import LinkImg from "../../assets/images/Link.png";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateDriver = () => {
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    motherName: "",
    emiratesId: "",
    contractor: "",
    localAddress: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter your name").min(3, "too Short"),
    email: Yup.string().required("Email is required").email("Email is invalid"),

    phone: Yup.string()
      .required("Mobile number is required")
      .min(10, "Mobile number is not valid")
      .max(10, "Mobile number is not valid"),
    motherName: Yup.string().required("Mother Name is required"),
    emiratesId: Yup.string().required("Emirates Id is required"),
    contractor: Yup.string().required("Contractor is required"),
    localAddress: Yup.string().required("Local Address is required"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        //await UserServices.addUser(values);
        toast.success("User added successfully");
        formik.resetForm();
      } catch (error) {
        toast.error("User added successfully");
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <MasterLayout>
      <div className="container">
        <div className="row">
          <div class="col-sm-1"></div>
          <div class="col-sm-10">
            <h3 className="text-start mt-3 text-right">DRIVER DETAILS</h3>
            <form onSubmit={formik.handleSubmit}>
              <div
                className="row"
                style={{
                  borderBottom: "1px solid #e6dddd",
                  marginBottom: "10px",
                }}
              >
                <div className="col-sm-6">
                  <label for="name" class="form-label">
                    {formik.errors.name ? (
                      <span name="error" className="text-danger">
                        {formik.errors.name}
                      </span>
                    ) : null}
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control rounded"
                      id="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <label for="email" class="form-label">
                    {formik.errors.email ? (
                      <level name="error" className="text-danger">
                        {formik.errors.email}
                      </level>
                    ) : null}
                  </label>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control rounded"
                      id="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <label for="phone" class="form-label">
                    {formik.errors.phone ? (
                      <span name="error" className="text-danger">
                        {formik.errors.phone}
                      </span>
                    ) : null}
                  </label>
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control rounded"
                      id="phone"
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      placeholder="Phone"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <label for="motherName" class="form-label">
                    {formik.errors.motherName ? (
                      <span name="error" className="text-danger">
                        {formik.errors.motherName}
                      </span>
                    ) : null}
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control rounded"
                      id="motherName"
                      onChange={formik.handleChange}
                      value={formik.values.motherName}
                      placeholder="Mother's Name"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <label for="emiratesId" class="form-label">
                    {formik.errors.emiratesId ? (
                      <span name="error" className="text-danger">
                        {formik.errors.emiratesId}
                      </span>
                    ) : null}
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control rounded"
                      id="emiratesId"
                      onChange={formik.handleChange}
                      value={formik.values.emiratesId}
                      placeholder="Emirates ID"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <label for="contractor" class="form-label">
                    {formik.errors.contractor ? (
                      <span name="error" className="text-danger">
                        {formik.errors.contractor}
                      </span>
                    ) : null}
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control rounded"
                      id="contractor"
                      onChange={formik.handleChange}
                      value={formik.values.contractor}
                      placeholder="Contractor"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <label for="localAddress" class="form-label">
                    {formik.errors.localAddress ? (
                      <span name="error" className="text-danger">
                        {formik.errors.localAddress}
                      </span>
                    ) : null}
                  </label>
                  <div className="form-group">
                    <textarea
                      type="text"
                      className="form-control rounded"
                      id="localAddress"
                      onChange={formik.handleChange}
                      value={formik.values.localAddress}
                      rows="3"
                      placeholder="Local Address"
                    ></textarea>
                  </div>
                </div>
                <div className="col-sm-6">
                  <label for="homeCountryAddress" class="form-label">
                    {formik.errors.homeCountryAddress ? (
                      <span name="error" className="text-danger">
                        {formik.errors.homeCountryAddress}
                      </span>
                    ) : null}
                  </label>
                  <div className="form-group">
                    <textarea
                      type="text"
                      className="form-control rounded"
                      id="homeCountryAddress"
                      onChange={formik.handleChange}
                      value={formik.values.homeCountryAddress}
                      rows="3"
                      placeholder="Home Country Address"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <h3 className="text-start mt-3 text-right">DRIVER DETAILS</h3>
                <div className="col-sm-6">
                  <div className="form-group">
                    <div className="custom-file-input-container">
                      <label
                        htmlFor="driving_licence_front"
                        className="custom-file-input-label"
                      >
                        <span>Driving License Front</span>
                        <img src={LinkImg} aria-hidden="true" />
                      </label>
                      <input
                        type="file"
                        className="custom-file-input"
                        id="driving_licence_front"
                        name="driving_licence_front"
                        onChange=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <div className="custom-file-input-container">
                      <label
                        htmlFor="driving_licence_back"
                        className="custom-file-input-label"
                      >
                        <span>Driving License Back</span>
                        <img src={LinkImg} aria-hidden="true" />
                      </label>
                      <input
                        type="file"
                        className="custom-file-input"
                        id="driving_licence_back"
                        name="driving_licence_back"
                        onChange=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <div className="custom-file-input-container">
                      <label
                        htmlFor="emirates_id_front"
                        className="custom-file-input-label"
                      >
                        <span>Emirates Id Front</span>
                        <img src={LinkImg} aria-hidden="true" />
                      </label>
                      <input
                        type="file"
                        className="custom-file-input"
                        id="emirates_id_front"
                        name="emirates_id_front"
                        onChange=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <div className="custom-file-input-container">
                      <label
                        htmlFor="emirates_id_back"
                        className="custom-file-input-label"
                      >
                        <span>Emirates Id Back</span>
                        <img src={LinkImg} aria-hidden="true" />
                      </label>
                      <input
                        type="file"
                        className="custom-file-input"
                        id="emirates_id_back"
                        name="emirates_id_back"
                        onChange=""
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-">
                  <div className="form-group">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-outline-primary save_btn"
                        type="submit"
                        disabled={formik.isSubmitting} // Disable the button while submitting
                      >
                        {formik.isSubmitting ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>{" "}
                            Saving...
                          </>
                        ) : (
                          "Save"
                        )}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <Link
                        to="/"
                        className="btn btn-outline-primary cancel_btn"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <ToastContainer />
          </div>
          <div class="col-sm-1"></div>
        </div>
      </div>
    </MasterLayout>
  );
};
export default CreateDriver;
