
import { useSelector } from 'react-redux';
import axios from 'axios';

const useGetData = () => {
  const accessToken = useSelector((state) => state.auth.token);

  const getData = async (props) => {
    const {url, cred} = props
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${url}`,
        cred,
        {
          headers: {
            Authorization: `Bearer ${accessToken?.token}`,
          },
        }
      );
     return response
    } catch (err) {
     throw err;
    } 
  };

  return {  getData };
};

export default useGetData;

