import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
export const MenuItems = [
  {
    id: "dashboard",
    label: "Dashboard",
    url: "/dashboard",
    submenu: [],
  },
  {
    id: "users",
    label: "Users",
    url: "/users",
    submenu: [],
  },
  {
    id: "drivers",
    label: "Drivers",
    icon: <KeyboardArrowDownIcon />,
    submenu: [
      { id: "drivers-approved", label: "Approved", url: "/drivers/approved" },
      { id: "drivers-rejected", label: "Rejected", url: "/drivers/rejected" },
    ],
  },
  {
    id: "dispatcher",
    label: "Dispatcher",
    icon: <KeyboardArrowDownIcon />,
    submenu: [
      { label: "Assigned", url: "/dispatcher/assigned" },
      { label: "All", url: "/dispatcher/all" },
    ],
  },
];
