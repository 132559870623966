import React, { useState } from "react";
import logo from "../../assets/images/logo-white.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link, useLocation } from "react-router-dom";
import { MenuItems } from "../../data/Menuitems";

const Sidebar = () => {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [openSubMenuId, setOpenSubMenuId] = useState(null);

  const toggleMenu = (menuId) => {
    setOpenMenuId((prevId) => (prevId === menuId ? null : menuId));
  };

  const toggleSubMenu = (menuId) => {
    setOpenSubMenuId((prevId) => (prevId === menuId ? null : menuId));
  };
  const menuItems = MenuItems;
  return (
    <div className={`sidebar  ${sidebarOpen ? "open" : ""}`}>
      <div className="logo">
        {" "}
        <img src={logo} alt="YallaDrive" />
      </div>
      <ul>
        {menuItems.map((menuItem) => (
          <li
            key={menuItem.id}
            className={menuItem.submenu.length > 0 ? "open" : ""}
          >
            <Link
              to={menuItem.url}
              className={openMenuId === menuItem.id ? "active" : ""}
              onMouseEnter={() => toggleMenu(menuItem.id)}
            >
              {menuItem.label}
              <span>
                {menuItem.submenu.length > 0 ? (
                  openMenuId === menuItem.id ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                ) : (
                  ""
                )}
              </span>
            </Link>
            {openMenuId === menuItem.id && menuItem.submenu.length > 0 && (
              <ul>
                {menuItem.submenu.map((submenuItem, index) => (
                  <li key={index}>
                    <Link
                      className={
                        openSubMenuId === submenuItem.id ? "active" : ""
                      }
                      to={submenuItem.url}
                      onClick={() => toggleSubMenu(submenuItem.id)}
                    >
                      {submenuItem.label}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
