import React from "react";
import MasterLayout from "../layout/MasterLayout";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateUser = () => {
  const initialValues = {
    name: "",
    phone: "",
    email: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter your name").min(3, "too Short"),
    email: Yup.string().required("Email is required").email("Email is invalid"),

    phone: Yup.string()
      .required("Mobile number is required")
      .min(10, "Mobile number is not valid")
      .max(10, "Mobile number is not valid"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        //await UserServices.addUser(values);
        toast.success("User added successfully");
        formik.resetForm();
      } catch (error) {
        toast.error("User added successfully");
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <MasterLayout>
      <div className="container">
        <div className="row">
          <div class="col-sm-1"></div>
          <div class="col-sm-10">
            <h3 className="text-start mt-3 text-right">DRIVER DETAILS</h3>
            <form onSubmit={formik.handleSubmit}>
              <div
                className="row"
                style={{
                  borderBottom: "1px solid #e6dddd",
                  marginBottom: "10px",
                }}
              >
                <div className="col-sm-6">
                  <label for="name" class="form-label">
                    {formik.errors.name ? (
                      <span name="error" className="text-danger">
                        {formik.errors.name}
                      </span>
                    ) : null}
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control rounded"
                      id="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <label for="email" class="form-label">
                    {formik.errors.email ? (
                      <level name="error" className="text-danger">
                        {formik.errors.email}
                      </level>
                    ) : null}
                  </label>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control rounded"
                      id="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <label for="phone" class="form-label">
                    {formik.errors.phone ? (
                      <span name="error" className="text-danger">
                        {formik.errors.phone}
                      </span>
                    ) : null}
                  </label>
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control rounded"
                      id="phone"
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      placeholder="Phone"
                    />
                  </div>
                </div>
              </div>
            </form>
            <ToastContainer />
          </div>
          <div class="col-sm-1"></div>
        </div>
      </div>
    </MasterLayout>
  );
};
export default CreateUser;
