import React from 'react'
import MasterLayout from '../layout/MasterLayout'

 

const Dashboard = () => {
  return (
    <MasterLayout>
      <h1 className='page-title text-dark'>Dashboard</h1>
   </MasterLayout>
  )
}

export default Dashboard