// DataTable.js
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import SkeletonRow from "./SkeletonRow";
import { CiSearch } from "react-icons/ci";
import "./DataTable.css";
const TableComponent = ({ columns, data, type }) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredData = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = (
    <>
      <div className="tableSearch w-25">
        <span>
          <CiSearch />
        </span>
        <input
          type="text"
          placeholder="Search..."
          value={filterText}
          className=" form-control"
          onChange={(e) => {
            setFilterText(e.target.value);
            setResetPaginationToggle(!resetPaginationToggle);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        {type == "not_show" ? (
          ""
        ) : (
          <button className="btn btn-primary">+ Create {type}</button>
        )}
      </div>
    </>
  );

  return (
    <>
      <SkeletonRow data={10} columns={columns} type={"User"} />
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponent}
        persistTableHead
      />
    </>
  );
};

export default TableComponent;
