import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Loginform from './Loginform';


const Login = () => {
  return (
    <div className='loginPage'>
        <Header />
        <Loginform />
        <Footer />
    </div>
  )
}

export default Login