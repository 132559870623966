import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import avatar from '../../assets/images/avatar.png';
import Dropdown from 'react-bootstrap/Dropdown';

const Header = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
   
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
   
  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-light  ">
            <div className="container-fluid">
                <div className='d-flex w-100 align-items-center'>
                    <div>
                        <button
                            className="sidebar-toggler text-dark"
                            type="button"
                            onClick={toggleSidebar}
                            >
                            <span className=""> <MenuIcon /></span>
                        </button>
                    </div>
                    <div  className='ms-3' >
                        <h1 className='main-title fw-bold  mb-0  '>Welcome, Mohd. Rahman</h1>
                        <p className='small mb-0'>Let’s check today’s status</p>
                    </div>

                    <div  className='ms-auto d-flex' >
                        <select className='me-5'>
                            <option>English</option>
                            <option>عربي</option>
                        </select>

                        <Dropdown>
                            <Dropdown.Toggle variant="none" id="dropdown-basic">
                               <img src={avatar} alt='YallaDrive' /> Mohd. 
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                            <Dropdown.Item  >Mohd. Rahman</Dropdown.Item>
                                <Dropdown.Item href="#/action-1">Profile</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Logout</Dropdown.Item>
                               
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                     
                       
                 
                </div>
                    


           
            </div>
        </nav>

       
    </div>
  )
}

export default Header