import Edit from "../../assets/images/edit.png";
import View from "../../assets/images/view.png";
import Delete from "../../assets/images/delete.png";
import document from "../../assets/images/document.svg";
import blockUser from "../../assets/images/blockUser.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
export const DriverColumn = [
  // {
  //   name: "Sr.No.",
  //   cell: (row, index) => index + 1,
  //   width: "100px",
  // },
  {
    name: "Driver Name",
    selector: (row) => row.name,
  },
  {
    name: "Contractor",
    selector: (row) => row.contractor,
  },
  {
    name: "Documents",
    cell: (row) => ( 
      <><img src={document} alt="YallaDrive" />  </>
    )
  },
  {
    name: "Email",
    selector: (row) => row.email,
  },
  {
    name: "Phone",
    selector: (row) => row.phone,
  },
  {
    name: "Rating",
    cell: (row) => ( 
      <><FontAwesomeIcon icon={faStar} style={{color:"#ffc239"}}/> <span style={{marginLeft:"5px"}}>{`  ${Number(row.rating).toFixed(2)}`}</span> </>
    )
  },
  {
    name: "Total Trips",
    selector: (row) => row.total_trips,
  },
  {
    name: "Wallet Balance",
    cell: (row) => ( 
      <><p style={{color:`${row.wallet_amount<0?'red':'green'}`}}> {row.wallet_amount}</p> </>
    )
  },
  {
    name: "Documents Status",
    cell: (row) => ( 
      <span className="badge badge-warning" style={{background:`${row.document_status=='Approved'?'#00d18a':'#ffc239'}`}}>{row.document_status}</span>
    )
  },
  // {
  //   name: "Status",
  //   selector: (row) => row.status,
  // },
  {
    name: "Actions",
    cell: (row) => (
      <div className="tbl_btns">
        <button className="btns" userId={`${row.id}`} title="Block And Unblock User">
          <img src={blockUser} alt="YallaDrive"  />
        </button>
        <button className="btns" userId={`${row.id}`}>
          <img src={View} alt="YallaDrive" />
        </button>
        <button className="btns" userId={`${row.id}`}>
          <img src={Edit} alt="YallaDrive" />
        </button>
        <button className="btns" userId={`${row.id}`}>
          <img src={Delete} alt="YallaDrive" />
        </button>
      </div>
    ),
    width: "150px",
  },
];
