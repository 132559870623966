import React from 'react'

const Footer = () => {
  return (
    <footer className='text-center'>
        <p className='text-muted'>© 2024 YallaDrive</p>
    </footer>
  )
}

export default Footer