import MasterLayout from "../layout/MasterLayout";
import React, { useState, useEffect, useCallback } from "react";
import { UserColumn } from "../../data/column/User";
import TableComponent from "../../components/DataTable/Table";
import SkeletonRow from "../../components/DataTable/SkeletonRow";
import useGetData from "../../Apis/Getters/GetData";

const Users = () => {
  const [data, setData] = useState([]);
  const { getData } = useGetData();
  const column = UserColumn;

  const fetchData = useCallback(async () => {
    const response = await getData({ url: "users", cred: { body: 1 } });
    if (response.data.status) {
      const processedData = response.data.data.usersData.map((user) => {
        return {
          name: user.name ? user.name : "N/A",
          email: user.email ? user.email : "N/A",
          phone: user.phone,
          rating: user.rating,
          wallet: "wallethistory",
          id: user.id,
        };
      });
      setData(processedData);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MasterLayout>
      <h1 className="page-title text-dark">Users</h1>
      <TableComponent data={data} columns={column} type={"User"} />
    </MasterLayout>
  );
};

export default Users;
