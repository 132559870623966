import { Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";

const UserInfo = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulate fetching data
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      <div className="row">
        <Col md="1"></Col>
        <div className="col-md-5">
          <div className="col-group">
            <label for="" className="font-weight-bold">
              Phone:
            </label>
            <span>7897188225</span>
          </div>
        </div>
        <div className="col-md-5">
          <div className="col-group">
            <label for="" className="font-weight-bold">
              Email:
            </label>
            <span>7897188225</span>
          </div>
        </div>
        <Col md="1"></Col>
      </div>
      <div className="row">
        <Col md="1"></Col>
        <div className="col-md-5">
          <div className="col-group">
            <label for="" className="font-weight-bold">
              Created At :
            </label>
            <span>23 May 2024 11:53 AM</span>
          </div>
        </div>
        <div className="col-md-5">
          <div className="col-group">
            <label for="" className="font-weight-bold">
              Edited:
            </label>
            <span>23 May 2024 12:46 PM</span>
          </div>
        </div>
        <Col md="1"></Col>
      </div>
      <div className="row">
        <Col md="1"></Col>
        <div className="col-md-5">
          <div className="col-group">
            <label for="" className="font-weight-bold">
              Wallet Balance:
            </label>
            <span>0.00</span>
          </div>
        </div>
        <div className="col-md-5">
          <div className="col-group">
            <label for="" className="font-weight-bold">
              Rating:
            </label>
            <span>0.00</span>
          </div>
        </div>
        <Col md="1"></Col>
      </div>
    </>
  );
};
export default UserInfo;
