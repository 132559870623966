import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/App.css";
import Login from "./login";
import Dashboard from "./pages/dashboard/index";
import Users from "./pages/users";
import Drivers from "./pages/driver";
import CreateDriver from "./pages/driver/create";
import CreateUser from "./pages/users/create";
import USerDetails from "./pages/users/details";
function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" exact={true} element={<Login />} />
          <Route path="/login" exact={true} element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/create" element={<CreateUser />} />
          <Route path="/users/details" element={<USerDetails />} />
          <Route path="/drivers/:status" element={<Drivers />} />
          <Route path="/drivers/create" element={<CreateDriver />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
