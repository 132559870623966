import React from "react";
import styled from "styled-components";

const SkeletonRow = ({ rows = 5, columns = 5 }) => {
  const rowArray = Array.from({ length: columns.length });
  const rowsArray = Array.from({ length: rows });

  const StyledTable = styled.table`
    .styled-td {
      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  `;

  return (
    <StyledTable className="skeleton-table">
      <thead>
        <tr style={{ background: "#f7f8fa", height: "50px" }}>
          {columns.map((item, index) => (
            <td
              key={index}
              className="styled-td"
              style={{
                width: item?.width,
                fontFamily: "Open Sans, sans-serif",
              }}
            >
              {item.name}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowsArray.map((_, rowIndex) => (
          <tr key={rowIndex}>
            {rowArray.map((_, cellIndex) => (
              <td key={cellIndex} className="skeleton-cell">
                <div className="skeleton-line"></div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default SkeletonRow;
