import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
  RemoveRedEye as RemoveRedEyeIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import loginBg from "../assets/images/login-bg.png";
import logo from "../assets/images/logo.png";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/slices/AuthSlice";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (accessToken?.token && accessToken?.token != null) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, [navigate, accessToken]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [type, setType] = useState("password");
  const [errors, setErrors] = useState({});
  ///// check validation

  const validate = () => {
    const errors = {};
    if (!email) {
      errors.email = "Email is required.";
    }
    if (!password) {
      errors.password = "Password is required.";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.status) {
          toast.success("Logged In");
          dispatch(login({ token: res.data.data.token }));
          // window.sessionStorage.setItem("access-yalla", res.data.data.token);
          navigate("/dashboard");
        } else {
          toast.error("Login failed. Please check your credentials.");
        }
      })
      .catch((error) => {
        toast.error("Login failed. Please check your credentials.");
        // console.error("There was an error!", error);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setType(type === "password" ? "text" : "password");
  };

  return (
    <div className="loginForm pt-4">
      <div className="row align-items-center">
        <div className="col-md-6">
          <img src={loginBg} alt="YallaDrive" className="leftImg" />
        </div>
        <div className="col-md-5 ms-auto">
          <div className="card border-0">
            <img className="logo" src={logo} alt="YallaDrive" />
            <h2 className="fw-bold text-black mt-5 pt-4">Sign In</h2>

            <form className="mt-4 pt-2" onSubmit={handleSubmit}>
              {errors.email && (
                <div className="text-danger">{errors.email}</div>
              )}
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Email or Phone"
                />
              </div>
              {errors.password && (
                <div className="text-danger">{errors.password}</div>
              )}
              <div className="form-group password">
                <input
                  type={type}
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
                <span className="eye-icon" onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                </span>
              </div>
              <div className="form-group text-end">
                <Link to="/recover-password">Recover Password?</Link>
              </div>
              <button
                type="submit"
                className="btn mt-4 btn-primary py-3 d-block w-100"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
