import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export const Vehicle = [
  {
    name: "Sr.No.",
    cell: (row, index) => index + 1,
    width: "100px",
  },
  {
    name: "Brand",
    selector: (row) => row.name,
  },
  {
    name: "Model",
    selector: (row) => row.email,
  },
  {
    name: "Car Number",
    selector: (row) => row.phone,
  },
  {
    name: "Color",
    selector: (row) => row.phone,
  },
  {
    name: "Created At",
    selector: (row) => row.phone,
  },
];
