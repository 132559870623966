import Edit from "../../assets/images/edit.png";
import View from "../../assets/images/view.png";
import Delete from "../../assets/images/delete.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faStar } from '@fortawesome/free-solid-svg-icons'; 
export const UserColumn = [
  {
    name: "Sr.No.",
    cell: (row, index) => index + 1,
    width: "100px",
  },
  {
    name: "Name",
    selector: (row) => row.name,
  },
  {
    name: "Email",
    selector: (row) => row.email,
  },
  {
    name: "Phone No.",
    selector: (row) => row.phone,
  },
  {
    name: "Rating",
    cell: (row) => ( 
      <><FontAwesomeIcon icon={faStar} style={{color:"#ffc239"}}/> <span style={{marginLeft:"5px"}}>{`  ${Number(row.rating).toFixed(2)}`}</span> </>
    )
  },
  {
    name: "Wallet History",
    selector: (row) => row.wallet,
  },
  {
    name: "Actions",
    cell: (row) => (
      <div className="tbl_btns">
        <button className="btns" userId={`${row.id}`}>
          <img src={View} alt="YallaDrive" />
        </button>
        <button className="btns" userId={`${row.id}`}>
          <img src={Edit} alt="YallaDrive" />
        </button>
        <button className="btns" userId={`${row.id}`}>
          <img src={Delete} alt="YallaDrive" />
        </button>
      </div>
    ),
    width: "150px",
  },
];
