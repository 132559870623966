import React, { useState } from "react";
import MasterLayout from "../layout/MasterLayout";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import UserInfo from "../../components/UserInfo";
import SkeletonRow from "../../components/DataTable/SkeletonRow";
import { TripColumn } from "../../data/column/Trips";
import { TransactionsColumn } from "../../data/column/UserWalletTransactions";
import { Vehicle } from "../../data/column/Vehicle";
import TableComponent from "../../components/DataTable/Table";
const UserDetails = () => {
  <SkeletonRow rows={5} columns={3} />;
  const [data, setData] = useState([]);
  const column = TripColumn;
  const walletColumn = TransactionsColumn;
  return (
    <MasterLayout>
      <Row className="justify-content-md-center">
        <div className="user-top">
          <div className="row align-items-center">
            <div className="user-profile col-md-2">
              <div className="profile-img">
                <img
                  className="profile-pic"
                  src="https://yalladriver.s3.ap-south-1.amazonaws.com/uploads/user_profile/1716466333945_profilePhoto.jpg"
                  alt="image"
                />
              </div>
            </div>
            <div className="user-title col-md-7">
              <h4 className="card-title"> Details of Deepansh </h4>
            </div>
          </div>
        </div>
        <Col md="12">
          <Tabs
            defaultActiveKey="information"
            id="uncontrolled-tab-example"
            className="center-tabs"
          >
            <Tab eventKey="information" title="Information">
              <UserInfo />
            </Tab>
            <Tab eventKey="trips" title="Trips">
              <TableComponent data={data} columns={column} type={"not_show"} />
            </Tab>
            <Tab eventKey="wallet-transactions" title="Wallet Transactions">
              <TableComponent
                data={data}
                columns={walletColumn}
                type={"not_show"}
              />
            </Tab>
            <Tab eventKey="vehicle" title="Vehicle">
              <TableComponent data={data} columns={Vehicle} type={"not_show"} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </MasterLayout>
  );
};

export default UserDetails;
