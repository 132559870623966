import MasterLayout from "../layout/MasterLayout";
import React, { useState, useEffect, useCallback } from "react";
import { DriverColumn } from "../../data/column/Driver";
import TableComponent from "../../components/DataTable/Table";
// import { GetData } from "../../Apis/Getters/GetData";
import { useParams } from "react-router-dom";
import useGetData from "../../Apis/Getters/GetData";

const Drivers = () => {
  const { status } = useParams();
  const { getData } = useGetData();
  const [data, setData] = useState([]);
  const column = DriverColumn;

  const fetchData = useCallback(async (status) => {
    const response = await getData({
      url: "driver",
      cred: { driver_type: status == "approved" ? 2 : 3 },
    });
    if (response.data.status) {
      const processedData = response.data.data.all_driver.map((driver) => {
        return {
          name: driver.name ? driver.name : "N/A",
          email: driver.email ? driver.email : "N/A",
          phone: driver.phone,
          rating: driver.rating,
          status: driver.status,
          wallet_amount: driver.wallet_amount,
          document_status: driver.document_status,
          rating: driver.rating,
          total_trips: driver.total_trips,
          id: driver.id,
          contractor: driver.contractor_name,
        };
      });
      setData(processedData);
    } else {
      setData([]);
    }
  }, []);
  useEffect(() => {
    if (status) {
      fetchData(status);
    }
  }, [status]);

  return (
    <MasterLayout>
      <h1 className="page-title text-dark">Users</h1>
      <TableComponent data={data} columns={column} type={"Driver"} />
    </MasterLayout>
  );
};

export default Drivers;
