
import { combineReducers } from '@reduxjs/toolkit';
import AuthSlice from '../slices/AuthSlice';
import ProfileSlice from '../slices/ProfileSlice';



const rootReducer = combineReducers({
  auth: AuthSlice,
  profile: ProfileSlice,
});

export default rootReducer;
