// import React, { useState } from 'react'

const Header = () => {
  //    const [language, setLanguage] = useState(1);
  return (
    <header>
      <div className="row align-items-center">
        <div className="col-md-4">
          <h1 className="title text-dark">
            Welcome to <span className="d-block">YallaDrive</span>
          </h1>
        </div>
        <div className="col-md-5">
          <p className="text-muted mt-3">
            We are delighted to offer a modern and user-friendly service to
            ensure you have the best experience.
          </p>
        </div>
        <div className="col-md-2 ms-auto text-md-end">
          <select className="form-control float-md-end">
            <option>English</option>
            <option>عربي</option>
          </select>
        </div>
      </div>
    </header>
  );
};

export default Header;
